


























































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { receiveItemsService } from "@/services-v2/receive-item.service";
import { CURRENCY_CODE, Mode } from "@enum/global.enum";
import { IMPORT_COST_ERROR } from "@enum/import-cost.enum";
import { importCostService } from "@service/import-cost.service";
import { IImportCostCreate, IImportCostDetail } from "@interface/import-cost.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY, DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { debounceProcess } from "@/helpers/debounce";
import { RequestReceivingItemUpdate } from "@/models/interface-v2/receiving-item.interface";
import localStorageService from "@/services/localStorage.service";
import { GR_LOCAL_STORAGE, GR_STATUS } from "@/models/enums/good-receipt.enum";
import { ContactData } from "@/models/interface-v2/contact.interface";

type Menu = "detail" | "productDetail" | "customerClearance" | "ppjkServiceDetail" | "shouldPay" | "warehouseRentDetail" | "unloadingCostDetail" | "documentFeeDetail" | "quarantineFeeDetail";

const DISALLOW_SUBMIT: GR_STATUS[] = [
  GR_STATUS.UNBILLED,
  GR_STATUS.CANCEL,
];

export default Vue.extend({
  name: "GoodReceiptImportCost",
  beforeRouteLeave(to, _from, next) {
    if (to && to.path.includes("receivingitems/goodreceipt/edit")) {
      return next();
    } else {
      this.resetStoreImportCost();
      return next();
    }
  },
  components: {
    SelectBank: () => import("./components/SelectBank.vue"),
    ImportCostDetail: () => import("./components/ImportCostDetail.vue"),
    ImportCostProductDetail: () => import("./components/ImportCostProductDetail.vue"),
    ImportCostCustomerClearance: () => import("./components/ImportCostCustomerClearance.vue"),
    ImportCostPPJKServiceDetail: () => import("./components/ImportCostPPJKServiceDetail.vue"),
    ImportCostTotalShouldPay: () => import("./components/ImportCostTotalShouldPay.vue"),
    ImportCostWarehouseRentDetail: () => import("./components/ImportCostWarehouseRentDetail.vue"),
    ImportCostUnloadingCostDetail: () => import("./components/ImportCostUnloadingCostDetail.vue"),
    ImportCostDocumentFeeDetail: () => import("./components/ImportCostDocumentFeeDetail.vue"),
    ImportCostQuarantineFeeDetail: () => import("./components/ImportCostQuarantineFeeDetail.vue"),
    ImportCostTable: () => import("./components/ImportCostTable.vue"),
    CSelectSupplier: () => import("@/components/shared/select-supplier/CSelectSupplier.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    this.commitState = debounceProcess(this.commitState, 300);
    return {
      CURRENCY_CODE,
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      tabList: [
        {
          key: "detail",
          title: "lbl_details",
          comp: "ImportCostDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "productDetail",
          title: "lbl_product_details",
          comp: "ImportCostProductDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "customerClearance",
          title: "lbl_customer_clearance_and_handling_details",
          comp: "ImportCostCustomerClearance",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "ppjkServiceDetail",
          title: "lbl_ppjk_service_details",
          comp: "ImportCostPPJKServiceDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "shouldPay",
          title: "lbl_total_should_pay",
          comp: "ImportCostTotalShouldPay",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "warehouseRentDetail",
          title: "lbl_warehouse_rent_details",
          comp: "ImportCostWarehouseRentDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "unloadingCostDetail",
          title: "lbl_unloading_cost_details",
          comp: "ImportCostUnloadingCostDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "documentFeeDetail",
          title: "lbl_document_fee_details",
          comp: "ImportCostDocumentFeeDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "quarantineFeeDetail",
          title: "lbl_quarantine_fee_details",
          comp: "ImportCostQuarantineFeeDetail",
          disabled: false,
          scopedSlots: { tab: "customRender" }
        },
      ],
      rules: {
        importCostNumber: [{ required: false, message: () => this.$("lbl_validation_required_error") }],
        supplierId: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        supplierBillToAddress: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        supplierBankAccount: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        checklistNumber: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        priceCurrencyId: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        currencyRate: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        fobTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        freight: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        cnf: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        ins: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        cif: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        airContributionTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        airContributionValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        beaMasuk: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        certificateFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        document: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        documentFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        grossWeight: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        handlingFeeTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        handlingFeeValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        importCostLines: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        kadeChargeTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        kadeChargeValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        labFeeTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        labFeeValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        movingCostDocumentFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        nilaiPabean: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        otherDocumentFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        otherQuarantineFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        otherUnloading: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        otherWarehouse: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        pjkp2uTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        pjkp2uValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        pph: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        pph23: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        ppjkEdi: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        ppn: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        price: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        product: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        receivingItemId: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        rushHoldingTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        rushHoldingValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        stampDuty: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        stampDutyDocumentFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        stampDutyUnloading: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        stampDutyWarehouse: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        storageChargeDay: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        storageChargeTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        storageChargeValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        storageChargeValue1: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxDocumentFeePercent: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxDocumentFeeTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxDocumentFeeValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxUnloadingPercent: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxUnloadingTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxWarehousePercent: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxWarehouseTotal: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        taxWarehouseValue: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalAmountPrice: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalCarton: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalConversion: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalDetail: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalDocumentFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalFeeAfterIDR: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalImportDuty: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalLocalTransportation: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalPayment: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalPortion: [{ required: false, message: () => this.$("lbl_validation_required_error") }],
        totalPpjk: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalPrice: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalQtyReceived: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalQuarantineFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalService: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalUnloadingCost: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        totalWarehouseRent: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        transportFee: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        travelExpanse: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
        weightNett: [{ required: true, message: () => this.$("lbl_validation_required_error") }],
      },
      goodReceiptId: "",
      loading: {
        submit: false,
      },
      mode: Mode.CREATE,
    };
  },
  computed: {
    ...mapState({
      form: (state: any) => state.importCostStore.form as IImportCostCreate,
      goodReceiptDetail: (state: any) => state.importCostStore.goodReceiptDetail,
      stateToggle: (state: any) => state.importCostStore.toggleSection,
    }),
    ...mapGetters({
      getGRStatus: "importCostStore/GET_STATUS_GOOD_RECEIPT",
    }),
    toggleIcon() {
      return {
        detail: this.stateToggle.detail ? "up" : "down",
        productDetail: this.stateToggle.productDetail ? "up" : "down",
        customerClearance: this.stateToggle.customerClearance ? "up" : "down",
        ppjkServiceDetail: this.stateToggle.ppjkServiceDetail ? "up" : "down",
        shouldPay: this.stateToggle.shouldPay ? "up" : "down",
        warehouseRentDetail: this.stateToggle.warehouseRentDetail ? "up" : "down",
        unloadingCostDetail: this.stateToggle.unloadingCostDetail ? "up" : "down",
        documentFeeDetail: this.stateToggle.documentFeeDetail ? "up" : "down",
        quarantineFeeDetail: this.stateToggle.quarantineFeeDetail ? "up" : "down",
      };
    },
    disallowSave(): boolean {
      return DISALLOW_SUBMIT.includes(this.getGRStatus);
    },
    isUnbilled(): boolean {
      return this.getGRStatus === GR_STATUS.UNBILLED;
    },
    isSubmitted(): boolean {
      return this.getGRStatus === GR_STATUS.SUBMITTED;
    },
    isWaitApproval(): boolean {
      return this.getGRStatus === GR_STATUS.WAIT_FOR_APPROVAL;
    },
    isModeEdit(): boolean {
      return this.mode === Mode.EDIT;
    },
    isModeDetail(): boolean {
      return this.mode === Mode.DETAIL;
    },
    isModeCreate(): boolean {
      return this.mode === Mode.CREATE;
    },
  },
  created(): void {
    if (this.$route.query.id) {
      this.goodReceiptId = this.$route.query.id as string;
      this.getDetailImportCost(this.goodReceiptId);
    }

    if (this.$route.query.status) {
      this.setGRStatus(this.$route.query.status);
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    ...mapMutations({
      setForm: "importCostStore/SET_FORM",
      toggle: "importCostStore/TOGGLE_SECTION_DETAIL",
      setGoodReceiptDetail: "importCostStore/SET_GOOD_RECEIPT_DETAIL",
      setGRStatus: "importCostStore/SET_GOOD_RECEIPT_STATUS",
    }),
    ...mapActions({
      fillForm: "importCostStore/ACT_FILL_FORM",
      recalculateAll: "importCostStore/RECALCULATE_ALL",
      resetStoreImportCost: "importCostStore/ACT_RESET_STATE",
    }),
    onSelectBank({ value }): void {
      this.commitState(value, "supplierBankAccount");
    },
    async getDetailGoodReceipt(id: string): Promise<void> {
      try {
        const res = await receiveItemsService.getDetailReceivingItem(id);
        this.setGoodReceiptDetail(res);
        this.fillForm();
      } catch (error) {
        console.error(error);
      }
    },
    async getDetailImportCost(id: string): Promise<void> {
      try {
        if (!id) return;
        const res: IImportCostDetail = await importCostService.getDetail(id);
        this.setForm({
          ...this.form,
          ...res,
          importCostLines: res.importCostLines.map((x, i) => ({...x, no: i + 1})),
        });
        this.mode = this.isWaitApproval ? Mode.EDIT : Mode.DETAIL;
      } catch (error: any) {
        if (error.data.message && error.data.message === IMPORT_COST_ERROR.NOT_FOUND) {
          this.mode = Mode.CREATE;
          this.getDetailGoodReceipt(id);
        }
      }
    },
    createPayload(): IImportCostCreate {
      return { ...this.form };
    },
    toggleSection(key: Menu): void {
      this.toggle(key);
    },
    onChangeField(value, field: string): void {
      this.commitState(value, field);
      this.recalculateAll();
    },
    commitState(value, field): void {
      this.setForm({
        ...this.form,
        [field]: value,
      });
    },
    handleBack(): void {
      if (!this.isSubmitted) sessionStorage.removeItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
      this.$router.push({ name: "logistic.receivingitems.edit", params: { id: this.goodReceiptId } });
    },
    validateForm(): void {
      const req = this.createPayload();
      let valid = true;
      const exceptions = ["importCostNumber", "transportFee", "supplierName", "checklistNumber", "priceCurrency"];
      let messages = "";
      for (const key in req) {
        if (!exceptions.includes(key) && (req[key] === null || req[key] === undefined)) {
          valid = false;
          messages = `${key} is not valid`;
        }
        if (!valid) break;
      }
      if (valid) {
        this.handleSave();
      } else {
        this.showNotifValidationError(messages);
      }
    },
    handleSave(): void {
      if (this.isModeCreate) {
        this.createImportCost();
      } else {
        this.updateImportCost(this.goodReceiptId);
      }
    },
    async createImportCost(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload: IImportCostCreate = this.createPayload();
        await importCostService.createNew(payload);
        this.handleSubmitGR();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    createPayloadGR(): RequestReceivingItemUpdate | undefined {
      const cache = sessionStorage.getItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
      if (!cache) {
        this.showNotifError("lbl_gr_not_found");
        return;
      }
      const formModel = JSON.parse(cache);
      const payload: RequestReceivingItemUpdate = {
        branchId: formModel.branchId,
        currencyId: formModel.currencyId || "",
        deletedReceiveItemIds: [],
        description: formModel.description,
        goodsReceiptChecklistId: this.goodReceiptId || "",
        purchaseOrderNumber: formModel.poNumber,
        rateCurrency: formModel.currencyRates || 0,
        receiveDate: formModel.receiveDate,
        receiveItems: [],
        supplierDeliveryOrderNo: formModel.supplierDoNumber,
        supplierBillToAddress: formModel.supplierBillTo,
        supplierId: formModel.supplierId,
        supplierShipToAddress: formModel.supplierShipToAddress,
        top: formModel.top ? +formModel.top : 0,
        supplierImport: formModel.supplierImport || false,
        taxCalculation: formModel.taxCalculation || null,
      };
      formModel.productLines.forEach(item => {
        payload.receiveItems.push({
          discount: item.discount.value,
          locationReceivedId: item.locationReceivedId,
          price: item.unitPrice,
          productId: item.productId,
          productUomId: item.productUomId,
          qty: item.qtyReceived,
          secureId: item.key,
          trackAsAsset: item.trackAsAsset,
          batchId: item.batchNumberId,
          qualityControl: { condition: item.condition },
          rateTax: item.taxRate,
          taxId: item.taxId || null,
          taxAmount: item.taxAmount,
          baseAmount: item.baseAmount,
          total: item.total,
          qtyPO: item.qtyPo,
        });
      });
      return payload;
    },
    async handleSubmitGR(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload: RequestReceivingItemUpdate = this.createPayloadGR();
        await receiveItemsService.submitReceiveItem(this.goodReceiptId, payload);
        this.showCreateSuccessMesssage();
        sessionStorage.removeItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async updateImportCost(id: string): Promise<void> {
      try {
        this.loading.submit = true;
        const payload = this.createPayload();
        payload.importCostLines.forEach((row) => row.id = row.secureId);
        await importCostService.update(id, payload);
        this.showUpdateSuccessMesssage();
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    onChangeSupplier(param: { value: string, meta: ContactData }): void {
      const address = param.meta.addressDataList.find(item => item.billTo && item.primaryBillTo);
      this.setForm({
        ...this.form,
        supplierBillToAddress: address ? `${address.address}, ${address.cityDistrict}, ${address.country}, ${address.postalCode}` : "",
      });
    },
    resetAddress(): void {
      this.setForm({
        ...this.form,
        supplierBillToAddress: "",
      });
    }
  }
});
