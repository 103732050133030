var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_import_cost") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                "label-align": "left"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_import_costing_number"),
                            prop: "importCostNumber"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.importCostNumber || "-"))
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_payment_to"),
                            prop: "supplierId"
                          }
                        },
                        [
                          _vm.isUnbilled
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.form.supplierName || "-"))
                              ])
                            : _c("CSelectSupplier", {
                                attrs: {
                                  "prop-search-by":
                                    "supplierData.supplierType~Service",
                                  "for-list": false,
                                  "prop-supplier-name": _vm.form.supplierName
                                },
                                on: {
                                  "on-select": _vm.onChangeSupplier,
                                  "on-clear": _vm.resetAddress
                                },
                                model: {
                                  value: _vm.form.supplierId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "supplierId", $$v)
                                  },
                                  expression: "form.supplierId"
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bill_to"),
                            prop: "supplierBillToAddress"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              value: _vm.form.supplierBillToAddress,
                              rows: { minRows: 1, maxRows: 4 },
                              "read-only": ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bank_details"),
                            prop: "supplierBankAccount"
                          }
                        },
                        [
                          _vm.isUnbilled
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.form.supplierBankAccount || "-")
                                )
                              ])
                            : _c("SelectBank", {
                                attrs: {
                                  value: _vm.form.supplierBankAccount,
                                  "supplier-id": _vm.form.supplierId
                                },
                                on: { "on-select": _vm.onSelectBank }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_good_receipt_checklist_number"),
                            prop: "checklistNumber"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.checklistNumber || "-"))
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "priceCurrencyId"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.priceCurrency || "-"))
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_rate"),
                            prop: "currencyRate"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("currency")(_vm.form.currencyRate))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                _vm._l(_vm.tabList, function(menu) {
                  return _c(
                    "a-col",
                    { key: menu.key, attrs: { span: 24 } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "mb-0 font-weight-bold text-dark",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function($event) {
                              return _vm.toggleSection(menu.key)
                            }
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t(menu.title)) + " "),
                          _c("a-icon", {
                            attrs: { type: _vm.toggleIcon[menu.key] }
                          })
                        ],
                        1
                      ),
                      _c(menu.comp, { key: menu.key, tag: "component" })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c("a-col", [_c("ImportCostTable")], 1),
              _c("a-col", [
                _c(
                  "table",
                  {
                    staticClass: "table-detail",
                    attrs: { "aria-describedby": "import cost table summary" }
                  },
                  [
                    _c("thead", [_c("th", { attrs: { colspan: "3" } })]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("lbl_total_qty_received_kgs")) + ":"
                          )
                        ]),
                        _c("td"),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toDecimalQty")(_vm.form.totalQtyReceived)
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_amount_price")) + ":")
                        ]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.form.priceCurrency || "-") + " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.form.totalAmountPrice)
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_portion")) + ":")
                        ]),
                        _c("td"),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.form.totalPortion)) +
                              "%"
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _c("span", { staticClass: "is-required" }, [
                            _vm._v(_vm._s(_vm.$t("lbl_transport_fee")) + ":")
                          ])
                        ]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.form.priceCurrency || "-") + " "
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c("a-input-number", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                value: _vm.form.transportFee,
                                placeholder: _vm.$t("lbl_type_here"),
                                formatter: _vm.formatterNumber,
                                parser: _vm.reverseFormatNumber,
                                precision: _vm.DECIMAL_PLACES_QTY,
                                min: 0,
                                readonly: _vm.isUnbilled
                              },
                              on: {
                                change: function(e) {
                                  return _vm.onChangeField(e, "transportFee")
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_fee")) + ":")
                        ]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.form.priceCurrency || "-") + " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalFee)))
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_conversion")) + ":")
                        ]),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))])
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.form.totalConversion))
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("lbl_total_local_transportation")) +
                              ":"
                          )
                        ]),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))])
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.form.totalLocalTransportation
                              )
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_import_duty")) + ":")
                        ]),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))])
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.form.totalImportDuty))
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_fee")) + ":")
                        ]),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))])
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.form.totalFeeAfterIDR)
                            )
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "save",
                            type: "primary",
                            disabled: _vm.disallowSave
                          },
                          on: { click: _vm.validateForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }