import { Api } from "@/models/class/api.class";
import { IGenericResponsePost } from "@/models/interface-v2/common.interface";
import { IImportCostCreate, IImportCostDetail } from "@/models/interface-v2/import-cost.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ImportCostServices extends HttpClient {
  constructor() {
    super();
  }

  /**
   * get detail import cost using goods receipt id
   * @param receiveId goods receipt id
   * @returns import cost form
   */
  getDetail(receiveId: string): Promise<IImportCostDetail> {
    return this.get<IImportCostDetail>(`${Api.ImportCost}/${receiveId}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  createNew(payload: IImportCostCreate): Promise<IGenericResponsePost> {
    return this.post<IGenericResponsePost, IImportCostCreate>(Api.ImportCost, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  update(id: string, payload: IImportCostCreate): Promise<IGenericResponsePost> {
    return this.put<IGenericResponsePost, IImportCostCreate>(`${Api.ImportCost}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const importCostService = new ImportCostServices();
